import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FormControl, FormControlLabel, MenuItem, Paper, Radio, RadioGroup, Select } from '@material-ui/core';

import { CsvDelimiter } from 'enums/CsvDelimiterEnum';
import { CsvType } from 'enums/CsvTypeEnum';
import classes from 'styles/App.module.scss';
import Button from '@material-ui/core/Button';
import fileDownload from 'js-file-download';

export default function MonthlyReport() {
    const [months, setMonths] = useState([]);
    const [scenarios, setScenarios] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState({ value: '', displayText: '' });
    const [csvDelimiter, setCsvDelimiter] = useState(CsvDelimiter.SEMICOLON);

    useEffect(() => {
        axios
            .get('/api/date/month')
            .then(resp => {
                setMonths(resp.data);
                setSelectedMonth(resp.data[0]);
            })
            .catch(console.warn);

        axios
            .get('/api/scenarios/resourceItems')
            .then(response => setScenarios(response.data))
            .catch(console.warn);
    }, []);

    const handleMonthSelection = event => {
        const freshlySelectedMonth = months.find(element => element.value === event.target.value);
        setSelectedMonth(freshlySelectedMonth);
    };

    const handleRadioChange = event => {
        setCsvDelimiter(event.target.value);
    };

    const handleSubmit = () => {
        const data = {
            csvType: CsvType.REPORT,
            selectedDateValue: selectedMonth.value,
            sepChar: csvDelimiter,
            selectedScenarioName: '',
        };

        axios
            .post(`/api/csv`, data)
            .then(response => {
                const filename = response.headers['content-disposition'].split('filename="')[1].slice(0, -1);
                fileDownload(response.data, filename);
            })
            .catch(console.warn);
    };

    return (
        <Paper className="paper" elevation={2}>
            <div className="sectionTitle">Havi kimutatás letöltése</div>
            <div className="verticalPlaceholder12px" />
            <div className={classes.paperBody}>
                <div className={classes.left}>
                    <b>Kimutatásban szereplő mérések:</b>
                    <ul>
                        {scenarios.map(scenario => (
                            <li key={scenario.name}>{scenario.description}</li>
                        ))}
                    </ul>
                </div>
                <div className={classes.right}>
                    <b>Hónap:</b>
                    <div className={classes.select}>
                        <FormControl>
                            <Select value={selectedMonth?.value} onChange={handleMonthSelection}>
                                {months.map(month => (
                                    <MenuItem key={month.value} value={month.value}>
                                        {month.displayText}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <b>CSV cellaelválasztó:</b>
                    <RadioGroup name="position" value={csvDelimiter}>
                        <FormControlLabel
                            value={CsvDelimiter.COMMA}
                            control={<Radio color="primary" />}
                            label="Vessző (Angol nyelvű Windows esetén)"
                            labelPlacement="end"
                            onChange={handleRadioChange}
                        />
                        <FormControlLabel
                            value={CsvDelimiter.SEMICOLON}
                            control={<Radio color="primary" />}
                            label="Pontosvessző (Magyar nyelvű Windows esetén)"
                            labelPlacement="end"
                            onChange={handleRadioChange}
                        />
                    </RadioGroup>
                </div>
            </div>
            <div className="verticalPlaceholder12px" />
            <div className={classes.submitButtonContainer}>
                <Button variant="contained" color="secondary" onClick={handleSubmit} className={classes.submitButton}>
                    Letöltés
                </Button>
            </div>
        </Paper>
    );
}
