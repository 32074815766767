export const ScenarioStatus = {
    PENDING: { displayName: 'Mérés függőben', color: 'blue' },
    SCHEDULED: { displayName: 'Mérés hamarosan', color: 'blue' },
    RUNNING: { displayName: 'Mérés folyamatban', color: 'blue' },
    COMPLETED: { displayName: 'Működik', color: 'green' },
    FAILED: { displayName: 'Nem működik', color: 'red' },
    FALSE: { displayName: 'Működik', color: 'green' },
    WARNING: { displayName: 'Működik, de hosszú SMS várakozási idő', color: 'orange' },
    TIMEOUT: { displayName: 'Időtúllépés', color: 'red' },
    NOTSTARTED: { displayName: 'Nincs mérés', color: 'red' },
    INTERRUPTED: { displayName: 'Megszakítva', color: 'red' },
    SERVER_ERROR: { displayName: 'Mérőszerver hiba', color: 'red' },
};
