import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

export default function ErrorHandler({ children }) {
    const [isSnackbarVisible, setSnackbarVisibility] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const history = useHistory();

    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.message === 'Network Error') {
                history.push('/logout');
            } else if (error.response?.status === 400) {
                setSnackbarVisibility(true);
                setSnackbarMessage(error.response?.data?.details[0] || 'Hibás kérés');
            } else if (error.response?.status === 401) {
                setSnackbarMessage('Hibás felhasználónév vagy jelszó.');
                setSnackbarVisibility(true);
            } else if (error.response?.status === 404) {
                setSnackbarVisibility(true);
                setSnackbarMessage(error.response?.data?.details[0] || 'A keresett oldal nem található');
                history.push('/');
            } else if (error.response?.status === 500) {
                setSnackbarVisibility(true);
                setSnackbarMessage('Szerveroldali hiba');
            }
            return Promise.reject(error);
        }
    );
    return (
        <>
            {children}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={isSnackbarVisible}>
                <Alert elevation={6} variant="filled" severity="error" onClose={() => setSnackbarVisibility(false)}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
