import React, { useState } from 'react';
import { Avatar, Button, CircularProgress, Paper, TextField, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import classes from './AuthPage.module.scss';

function LoginPage({ setLoggedInUser }) {
    const history = useHistory();

    const [auth, setAuth] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const inputHandler = event => {
        const { target } = event;
        setAuth(currentAuthData => ({ ...currentAuthData, [target.name]: target.value }));
    };

    const submitHandler = event => {
        event.preventDefault();
        setIsSubmitting(true);

        axios
            .get('/api/users/authenticate', { auth })
            .then(response => {
                setIsSubmitting(false);
                const user = response.data;
                user.isAdmin = user.authorities.some(a => a.authority === 'ROLE_ADMIN');
                localStorage.setItem('user', JSON.stringify(user));
                setLoggedInUser(user);
                history.push('/overview');
            })
            .catch(error => {
                setIsSubmitting(false);
                console.warn(error.response);
            });
    };

    return (
        <main className={classes.main}>
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <form onSubmit={submitHandler}>
                    <Typography component="h1" variant="h5">
                        Bejelentkezés
                    </Typography>
                    <br />
                    <TextField className={classes.formField} label="Felhasználónév" name="username" variant="outlined" fullWidth onChange={inputHandler} />
                    <br />
                    <TextField
                        className={classes.formField}
                        label="Password"
                        name="password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        onChange={inputHandler}
                    />
                    <br />
                    <Button
                        className={`button-blue ${classes.submitButton}`}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!auth?.username || !auth?.password}
                        fullWidth
                    >
                        {isSubmitting ? <CircularProgress color="secondary" size="1.5rem" /> : 'Belépek'}
                    </Button>
                </form>
            </Paper>
        </main>
    );
}

export default React.memo(LoginPage);
