import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Logout from 'routes/Auth/Logout';
import LoginPage from 'routes/Auth/LoginPage';
import Overview from 'routes/Overview/Overview';
import MonthlyReport from 'routes/MonthlyReport/MonthlyReport';
import ScenarioDetails from 'routes/ScenarioDetails/ScenarioDetails';
import MonthlyLog from 'routes/MonthlyLog/MonthlyLog';
import Dashboard from 'routes/Dashboard/Dashboard';
import Balance from 'routes/Balance/Balance';

const Routing = ({ loggedInUser, setLoggedInUser }) => {
    return (
        <>
            {loggedInUser ? (
                <Switch>
                    <Route path="/overview" exact>
                        <Overview />
                    </Route>
                    {loggedInUser.isAdmin && (
                        <Route path="/dashboard" exact>
                            <Dashboard />
                        </Route>
                    )}
                    {loggedInUser.isAdmin && (
                        <Route path="/balance" exact>
                            <Balance />
                        </Route>
                    )}
                    <Route path="/scenario-details/:scenarioId" exact>
                        <ScenarioDetails />
                    </Route>
                    <Route path="/monthly-report" exact>
                        <MonthlyReport />
                    </Route>
                    <Route path="/monthly-log" exact>
                        <MonthlyLog />
                    </Route>
                    <Route path="/raw-data" exact>
                        <MonthlyLog rawData />
                    </Route>
                    <Route path="/logout" exact>
                        <Logout setLoggedInUser={setLoggedInUser} />
                    </Route>
                    <Route path="/login" exact>
                        <Overview />
                    </Route>
                    <Route>
                        <Overview />
                    </Route>
                </Switch>
            ) : (
                <Switch>
                    <Route>
                        <LoginPage setLoggedInUser={setLoggedInUser} />
                    </Route>
                </Switch>
            )}
        </>
    );
};

export default Routing;
