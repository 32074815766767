import { useCallback, useState } from 'react';

export function usePaginationAndSorting(initialSortParams, defaultPage = 0, defaultSize = 10, rowsPerPageOptions = [10, 25, 100]) {
    const [page, setPage] = useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = useState(defaultSize);
    const [sortParams, setSortParams] = useState(initialSortParams);

    const buildPaginationQueryString = useCallback(() => {
        let queryString = `?page=${page}&size=${rowsPerPage}`;
        Object.entries(sortParams).forEach(([key, value]) => {
            if (value.active) {
                queryString = `${queryString}&sort=${key},${value.direction}`;
            }
        });
        return queryString;
    }, [sortParams, page, rowsPerPage]);

    const onChangePage = (event, selectedPage) => {
        setPage(selectedPage);
    };

    const onChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSortChange = key => {
        let sortedColumn = { ...sortParams[key] };
        if (sortedColumn.active) {
            if (sortedColumn.direction === 'asc') {
                sortedColumn = { ...sortedColumn, direction: 'desc' };
            } else {
                sortedColumn = { ...sortedColumn, active: false, direction: 'asc' };
            }
        } else {
            sortedColumn = { ...sortedColumn, active: true };
        }
        setSortParams(prevState => ({ ...prevState, [key]: sortedColumn }));
        setPage(0);
    };

    return {
        paginationConfig: { page, rowsPerPage, onChangePage, onChangeRowsPerPage, rowsPerPageOptions },
        setPage,
        sortParams,
        handleSortChange,
        buildPaginationQueryString,
    };
}
