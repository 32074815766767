import React from 'react';
import { ServerStatus } from 'enums/ServerStatusEnum';
import classes from 'routes/ScenarioDetails/TabData.module.scss';

export default function ScenarioPanel(scenarioDetails, resetDetails, getDispatcherDetails) {
    function evaluateLastSuccessfulTime(lastTime, lastSuccessfulTime) {
        return lastSuccessfulTime === 'Nincs adat' || lastTime !== lastSuccessfulTime ? 'last-time-not-ok' : 'last-time-ok';
    }

    return (
        <div className="details-container">
            <h4 className={classes.sectionTitle}>
                {scenarioDetails.scenarioInfo.scenarioName} scenario
                <span className="close-button bold" onClick={resetDetails}>
                    [x]
                </span>
            </h4>
            <div className={classes.section}>
                <div className={classes.keyContainer}>
                    <p>Runner ip:</p>
                    {scenarioDetails.dispatchers.length > 0 && <p>Dispatcherek:</p>}
                    <p>Utolsó mérés ideje:</p>
                    <p>Utolsó sikeres mérés ideje:</p>
                    <p>Sikeres átlag hossz:</p>
                    <p>Historikus sikeres átlag hossz:</p>
                    <p>Scenario állapota:</p>
                </div>
                <div>
                    <p>{scenarioDetails.scenarioInfo.runnerIp}</p>
                    <p>
                        {scenarioDetails.dispatchers.map(dispatcher => (
                            <span
                                key={dispatcher.dispatcherIp}
                                className={`text${ServerStatus[dispatcher.dispatcherState].color} connected-servers clickable`}
                                onClick={() => getDispatcherDetails(dispatcher.dispatcherIp)}
                            >
                                {dispatcher.dispatcherIp}
                            </span>
                        ))}
                    </p>
                    <p>{scenarioDetails.scenarioInfo.lastReport}</p>
                    <p className={evaluateLastSuccessfulTime(scenarioDetails.scenarioInfo.lastReport, scenarioDetails.scenarioInfo.lastSuccessfulReport)}>
                        {scenarioDetails.scenarioInfo.lastSuccessfulReport}
                    </p>
                    <p>{scenarioDetails.scenarioInfo.averageDuration}</p>
                    <p>{scenarioDetails.scenarioInfo.historicalAverageDuration}</p>
                    <p className={`text${ServerStatus[scenarioDetails.scenarioInfo.scenarioState].color} dispatcher bold`}>
                        {scenarioDetails.scenarioInfo.errorMessage}
                    </p>
                </div>
            </div>
        </div>
    );
}
