export const muiTheme = {
    palette: {
        common: { black: '#000', white: '#fff' },
        background: { paper: '#fff', default: '#fff' },
        primary: {
            light: '#7D95BC',
            main: '#54709D',
            dark: '#365585',
            contrastText: '#fff',
        },
        secondary: {
            light: '#FFDDA2',
            main: '#e8a838',
            dark: '#C99946',
            contrastText: 'rgba(256, 256, 256, 1)',
        },
        error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
        text: {
            primary: 'rgba(17, 16, 16, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
    },
};
