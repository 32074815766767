import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import 'App.scss';
import 'styles/balance.scss';
import classes from 'routes/ScenarioDetails/TabData.module.scss';

function createTableData(user, accountType, accountNumber, balance, currency, dateOfChecking) {
    const isBalanceLow = balance < 1000;
    return { user, accountType, accountNumber, balance, isBalanceLow, currency, dateOfChecking };
}
export default function Balance() {
    const [balanceData, setBalanceData] = useState();

    function getBalanceData() {
        axios
            .get('/api/accountinfo/all')
            .then(response => {
                const tableRows = response.data.map(row =>
                    createTableData(row.user, row.accountType, row.accountNumber, row.balance, row.currency, row.dateOfChecking)
                );
                setBalanceData(tableRows.sort((a, b) => (a.user > b.user ? 1 : -1)));
            })
            .catch(console.warn);
    }

    useEffect(() => {
        getBalanceData();
    }, []);

    return (
        <Paper className="table-container" elevation={2}>
            <div className="balance-container">
                <h4 className={classes.sectionTitle}>Számlaegyenlegek</h4>
                <div className="balance-list bold">
                    <p>Felhasználó</p>
                    <p>Számlaszám</p>
                    <p>Egyenleg</p>
                    <p>Dátum</p>
                </div>
                {balanceData?.map(row => (
                    <div key={row.accountNumber} className="balance-list">
                        <p>{row.user}</p>
                        <p>{row.accountNumber}</p>
                        <p className={row.isBalanceLow ? 'warning' : 'bold'}>
                            {row.balance.toLocaleString()} {row.currency}
                        </p>
                        <p>{row.dateOfChecking}</p>
                    </div>
                ))}
            </div>
        </Paper>
    );
}
