import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Paper, Tab, Tabs } from '@material-ui/core';

import TabPanel from 'common/TabPanel/TabPanel';
import MainData from './MainData/MainData';
import FailTraces from './FailTraces/FailTraces';
import Charts from './Charts/Charts';

export default function ScenarioDetails() {
    const [data, setData] = useState();
    const [activeTab, setActiveTab] = useState(0);

    const { scenarioId } = useParams();

    useEffect(() => {
        axios
            .get(`/api/scenarios/overview/${scenarioId}`)
            .then(resp => setData(resp.data))
            .catch(console.warn);
    }, [scenarioId]);

    const handleTabChange = (event, newValue) => setActiveTab(newValue);

    return (
        <Paper className="paper" elevation={2}>
            {data && (
                <>
                    <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
                        <Tab label="Fő adatok" />
                        <Tab label="Kiesések" />
                        <Tab label="Historikus adatok" />
                    </Tabs>
                    <TabPanel value={activeTab} index={0}>
                        <MainData data={data} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <FailTraces data={data} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <Charts data={data} />
                    </TabPanel>
                </>
            )}
        </Paper>
    );
}
