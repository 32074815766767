import React, { useEffect, useCallback } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

function Logout({ setLoggedInUser }) {
    const history = useHistory();

    const handleLogout = useCallback(() => {
        localStorage.clear();
        setLoggedInUser(null);
        history.push('/login');
    }, [history, setLoggedInUser]);

    useEffect(() => {
        axios
            .post('/api/logout')
            .then(handleLogout)
            .catch(error => {
                console.warn(error);
                handleLogout();
            });
    }, [handleLogout]);

    return <h1>Kijelentkezés...</h1>;
}

export default Logout;
