export const ScenarioResult = {
    SUCCESSFUL: { displayName: 'sikeres', color: 'green' },
    REDIRECTION: { displayName: 'redirection', color: 'red' },
    CLIENT_ERROR: { displayName: 'client error', color: 'red' },
    SERVER_ERROR: { displayName: 'server error', color: 'red' },
    VALIDITY_ERROR: { displayName: 'validity error', color: 'red' },
    MONITOR_ERROR: { displayName: 'monitor error', color: 'red' },
    COMM_ERROR: { displayName: 'comm error', color: 'red' },
    ELECTRA_ERROR: { displayName: 'electra error', color: 'red' },
    UNDEF_ERROR: { displayName: 'undef error', color: 'red' },
    SMS_WARNING: { displayName: 'sms warning', color: 'red' },
    SMSTIMEOUT: { displayName: 'sms timeout', color: 'red' },
    SELENIUM_ERROR: { displayName: 'selenium error', color: 'red' },
    TIMEOUT: { displayName: 'timeout', color: 'red' },
    FAILED: { displayName: 'failed', color: 'red' },
    NO_SUCH_ELEMENT: { displayName: 'no such element', color: 'red' },
    NETWORK_ERROR: { displayName: 'network error', color: 'red' },
};
