import React from 'react';

import classes from './Footer.module.scss';

export default function Footer({ userName }) {
    const date = new Date();
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
    const formattedDateTime = new Intl.DateTimeFormat('hu-HU', options).format(date);

    return (
        <div className={classes.footerContainer}>
            {userName && <p className={classes.footer}>Bejelentkezve: {userName}</p>}
            <p className={classes.footer}>
                Szerver idő: {formattedDateTime} | &#169; QTC Kft. {date.getFullYear()}.
            </p>
        </div>
    );
}
