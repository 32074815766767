import React from 'react';
import { ServerStatus } from 'enums/ServerStatusEnum';
import classes from 'routes/ScenarioDetails/TabData.module.scss';

export default function DispatcherPanel(dispatcherDetails, resetDetails, getScenarioDetails) {
    function evaluateLastSuccessfulTime(lastTime, lastSuccessfulTime) {
        return lastSuccessfulTime === 'Nincs adat' || lastTime !== lastSuccessfulTime ? 'last-time-not-ok' : 'last-time-ok';
    }

    return (
        <div className="details-container">
            <h4 className={classes.sectionTitle}>
                Dispatcher {dispatcherDetails.dispatcherInfo.dispatcherIp}
                <span className="close-button bold" onClick={resetDetails}>
                    [x]
                </span>
            </h4>
            <div className={classes.section}>
                <div className={classes.keyContainer}>
                    <p>Scenario:</p>
                    <p>Sikeres ping:</p>
                    <p>Utolsó mérés ideje:</p>
                    <p>Utolsó sikeres mérés ideje:</p>
                    <p>Dispatcher állapota:</p>
                </div>
                <div>
                    <p>
                        {dispatcherDetails.scenarios.map(scenario => (
                            <span
                                key={scenario.runnerIp + scenario.scenarioName}
                                data-title={scenario.runnerIp}
                                className={`text${ServerStatus[scenario.scenarioState]?.color} connected-servers clickable`}
                                onClick={() => getScenarioDetails(scenario.runnerIp, scenario.scenarioName)}
                            >
                                {scenario.scenarioName}
                            </span>
                        ))}
                    </p>
                    <p>{dispatcherDetails.dispatcherInfo.pingSuccessRate} %</p>
                    <p>{dispatcherDetails.dispatcherInfo.lastReport}</p>
                    <p
                        className={evaluateLastSuccessfulTime(
                            dispatcherDetails.dispatcherInfo.lastReport,
                            dispatcherDetails.dispatcherInfo.lastSuccessfulReport
                        )}
                    >
                        {dispatcherDetails.dispatcherInfo.lastSuccessfulReport}
                    </p>
                    <p className={`text${ServerStatus[dispatcherDetails.dispatcherInfo.dispatcherState].color} dispatcher bold`}>
                        {dispatcherDetails.dispatcherInfo.errorMessage}
                    </p>
                </div>
            </div>
        </div>
    );
}
