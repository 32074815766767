import { ScenarioResult } from 'enums/ScenarioResultEnum';
import { DashboardDetailType } from 'enums/DashboardDetailTypeEnum';
import React from 'react';
import classes from 'routes/ScenarioDetails/TabData.module.scss';

export default function ReportPanel(reports, sourcePanelName, getReportDurationData, reportDurationData, closeDurationInfo) {
    return (
        <div>
            {reportDurationData && (
                <div className="duration-container">
                    <h4 className={classes.sectionTitle}>
                        {reportDurationData.date} - {reportDurationData.scenarioName} - {reportDurationData.user}
                        <span className="close-button bold" onClick={closeDurationInfo}>
                            [x]
                        </span>
                    </h4>
                    <div className="report-list bold">
                        <p>Lépés</p>
                        <p>Mérés hossza</p>
                        <p>Átlag hossz</p>
                        <p>Historikus átlag</p>
                    </div>
                    {reportDurationData.stepDurationDataList.map(step => (
                        <div key={step.stepName} className="report-list">
                            <p>{step.stepName}</p>
                            <p>{step.duration}</p>
                            <p>{step.averageDuration}</p>
                            <p>{step.historicalAverageDuration}</p>
                        </div>
                    ))}
                    <div className="report-list bold">
                        <p>Teljes mérés</p>
                        <p>{reportDurationData.duration}</p>
                        <p>{reportDurationData.averageDuration}</p>
                        <p>{reportDurationData.historicalAverageDuration}</p>
                    </div>
                </div>
            )}
            <div className="details-container">
                <h4 className={classes.sectionTitle}>Mérések</h4>
                <div className="report-list bold">
                    <p>Mérés idő</p>
                    <p>Mérés hossz</p>
                    {DashboardDetailType.DISPATCHER === sourcePanelName && <p>Scenario</p>}
                    {DashboardDetailType.SCENARIO === sourcePanelName && <p>Dispatcher Ip</p>}
                    <p>Felhasználó</p>
                    <p>Sikertelen lépés</p>
                    <p>Eredmény</p>
                </div>
                {reports.map(report => (
                    <div key={report.user + report.duration + report.date} className="report-list">
                        <p>{report.date}</p>
                        <p
                            className={`clickable text${report.durationDeviant ? 'red bold' : 'black'}`}
                            onClick={() => getReportDurationData(report.id, report.scenarioName)}
                        >
                            {report.duration}
                        </p>
                        {DashboardDetailType.DISPATCHER === sourcePanelName && <p>{report.scenarioName}</p>}
                        {DashboardDetailType.SCENARIO === sourcePanelName && <p>{report.dispatcherIp}</p>}
                        <p>{report.user}</p>
                        <p>{report.failedStep}</p>
                        <p className={`text${ScenarioResult[report.result]?.color} bold`}>{ScenarioResult[report.result]?.displayName}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
