import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { ScenarioStatus } from 'enums/ScenarioStatusEnum';
import { constants } from 'utils/contstants';
import 'App.scss';

function createTableData(id, description, status, lastFinishedDate, lastScenarioReportIp, availability, bestPossibleAvailability) {
    return { id, description, status, lastFinishedDate, lastScenarioReportIp, availability, bestPossibleAvailability };
}

export default function Overview() {
    const [rows, setRows] = useState();
    const history = useHistory();

    useEffect(() => {
        axios
            .get('/api/scenarios')
            .then(resp => {
                const tableRows = resp.data.map(row =>
                    createTableData(
                        row.id,
                        row.description,
                        row.status,
                        row.lastFinishedDate,
                        row.lastScenarioReportIp,
                        row.availability,
                        row.bestPossibleAvailability
                    )
                );
                setRows(tableRows);
            })
            .catch(console.warn);
    }, []);

    const handleClick = (event, id) => {
        history.push(`scenario-details/${id}`);
    };

    return (
        <Paper className="table-container" elevation={2}>
            <h2 className="blue-text">Tesztelt szolgáltatások</h2>

            <Table>
                <TableHead>
                    <TableRow className="table-header">
                        <TableCell />
                        <TableCell className="blue-text" align="right">
                            Legutolsó állapot
                        </TableCell>
                        <TableCell className="blue-text" align="right">
                            Legutolsó mérés ideje
                        </TableCell>
                        <TableCell className="blue-text" align="right">
                            Mérőláb
                        </TableCell>
                        <TableCell className="blue-text" align="right">
                            Aktuális rend.állás *
                        </TableCell>
                        <TableCell className="blue-text" align="right">
                            Várható legjobb rend.állás **
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map(row => (
                        <TableRow hover onClick={event => handleClick(event, row.id)} key={row.description} className="pointer">
                            <TableCell className="blue-text" component="th" scope="row">
                                {row.description || constants.NO_DATA}
                            </TableCell>
                            <TableCell align="right" className={`text${ScenarioStatus[row.status]?.color || 'darkblue'}`}>
                                {ScenarioStatus[row.status]?.displayName || constants.NO_DATA}
                            </TableCell>
                            <TableCell className="blue-text" align="right">
                                {row.lastFinishedDate || constants.NO_DATA}
                            </TableCell>
                            <TableCell className="blue-text" align="right">
                                {row.lastScenarioReportIp || constants.NO_DATA}
                            </TableCell>
                            <TableCell className="blue-text" align="right">
                                {row.availability || constants.NO_DATA}
                            </TableCell>
                            <TableCell className="blue-text" align="right">
                                {row.bestPossibleAvailability || constants.NO_DATA}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <br />
            <div className="table-sub-text-container">
                <p className="blue-text table-sub-text">* Tegnapi nappal bezárólag.</p>
                <p className="blue-text table-sub-text">** Amennyiben a hónap hátralévő részében nincs kiesés.</p>
            </div>
        </Paper>
    );
}
