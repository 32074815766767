import React from 'react';

import { ScenarioStatus } from 'enums/ScenarioStatusEnum';
import classes from 'routes/ScenarioDetails/TabData.module.scss';

export default function MainData({ data }) {
    return data ? (
        <>
            <h4 className={classes.sectionTitle}>{data.scenarioDetailsItem.description}</h4>
            <div className={classes.section}>
                <div className={classes.keyContainer}>
                    <p>Legutolsó mérés ideje:</p>
                    <p>Legutolsó mérés eredménye:</p>
                    <p>Mérési frekvencia:</p>
                </div>
                <div>
                    <p>{data.scenarioDetailsItem.lastFinishedDate}</p>
                    <p className={`text${ScenarioStatus[data.scenarioDetailsItem.status].color}`}>
                        {ScenarioStatus[data.scenarioDetailsItem.status].displayName}
                    </p>
                    <p>{data.scenarioDetailsItem.scheduleInterval}</p>
                </div>
            </div>
            <h4 className={classes.sectionTitle}>Rendelkezésre állás</h4>
            <div className={classes.section}>
                <div className={classes.keyContainer}>
                    <p>{data.scenarioDpmoItem.actualMonth}</p>
                    <p>{data.scenarioDpmoItem.actualWeek}</p>
                </div>
                <div>
                    <p>{data.scenarioDpmoItem.actualMonthAvailability || 'nincs adat'} </p>
                    <p>{data.scenarioDpmoItem.actualWeekAvailability || 'nincs adat'} </p>
                </div>
            </div>
            <h4 className={classes.sectionTitle}>Tesztlépések</h4>
            <div className={classes.section}>
                <div className={classes.keyContainer}>
                    {data.scenarioStepsItem.steps.map((row, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p key={index}>{row.name}</p>
                    ))}
                </div>
                <div>
                    {data.scenarioStepsItem.steps.map((row, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p key={index}>{row.description}</p>
                    ))}
                </div>
            </div>
        </>
    ) : (
        <h4>Loading...</h4>
    );
}
