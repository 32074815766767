import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import { usePaginationAndSorting } from 'hooks/usePaginationAndSorting';
import { constants } from 'utils/contstants';
import { PeriodType } from 'enums/PeriodTypeEnum';
import classes from 'routes/ScenarioDetails/TabData.module.scss';
import IconButton from '@material-ui/core/IconButton';

export default function FailTraces({ data }) {
    const [dpmoAvailability, setDpmoAvailability] = useState();
    const [failtraceItems, setFailtraceItems] = useState();
    const [totalElements, setTotalElements] = useState(0);
    const [months, setMonths] = useState();
    const [selectedMonth, setSelectedMonth] = useState();
    const [checkedBoxes, setCheckedBoxes] = useState();
    const [modifiedFailtraceItems, setModifiedFailtraceItems] = useState({});
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [isSavingInProgress, setIsSavingInProgress] = useState(false);
    const [isSnackbarVisible, setSnackbarVisibility] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const { paginationConfig, buildPaginationQueryString } = usePaginationAndSorting({});

    useEffect(() => {
        axios
            .get('/api/date/month')
            .then(resp => {
                setMonths(resp.data);
                setSelectedMonth(resp.data[0]);
            })
            .catch(console.warn);
    }, [data.scenarioDetailsItem.id]);

    const fetchFailtraces = (month, paginationQueryString, paginationChange) => {
        if (paginationQueryString) {
            axios
                .get(`/api/scenarios/${data.scenarioDetailsItem.id}/failtraces/${month.value}${paginationQueryString}`)
                .then(response => {
                    const checked = checkedBoxes ? [...checkedBoxes] : [];
                    if (!paginationChange) {
                        response.data.content.forEach(failtraceItem => {
                            if (!failtraceItem.reported && !checked.includes(failtraceItem.id)) {
                                checked.push(failtraceItem.id);
                            }
                        });
                    }
                    setCheckedBoxes(checked);
                    setFailtraceItems(response.data.content);
                    setTotalElements(response.data.totalElements);
                })
                .catch(console.warn);

            if (!paginationChange) {
                axios
                    .get(`/api/scenarios/${data.scenarioDetailsItem.id}/dpmo/${PeriodType.MONTHS.value}/${month.value}`)
                    .then(response => setDpmoAvailability(response.data.availability))
                    .catch(console.warn);
            }
        }
    };

    useEffect(() => {
        if (selectedMonth) {
            const paginationQueryString = buildPaginationQueryString();
            if (paginationQueryString) {
                fetchFailtraces(selectedMonth, paginationQueryString, false);
            }
        }
        // eslint-disable-next-line
    }, [selectedMonth]);

    useEffect(() => {
        if (selectedMonth) {
            const paginationQueryString = buildPaginationQueryString();
            if (paginationQueryString) {
                fetchFailtraces(selectedMonth, paginationQueryString, true);
            }
        }
        // eslint-disable-next-line
    }, [buildPaginationQueryString]);

    const handleMonthSelection = event => {
        const freshlySelectedMonth = months.find(element => element.value === event.target.value);
        setSelectedMonth(freshlySelectedMonth);
        fetchFailtraces(freshlySelectedMonth);
    };

    const handleCheckboxChange = (event, failtraceId) => {
        const { checked } = event.target;

        setCheckedBoxes(checked ? [...checkedBoxes, failtraceId] : checkedBoxes.filter(element => element !== failtraceId));

        setModifiedFailtraceItems(currentModifiedFailtraceItems => ({
            ...currentModifiedFailtraceItems,
            [failtraceId]: !checked,
        }));

        if (isSaveButtonDisabled) {
            setIsSaveButtonDisabled(false);
        }
    };

    const save = () => {
        setIsSavingInProgress(true);
        setIsSaveButtonDisabled(true);

        const dataToSend = {
            scenarioId: data.scenarioDetailsItem.id,
            dateValue: selectedMonth.value,
            modifiedFailTraceItems: modifiedFailtraceItems,
        };

        axios
            .put(`/api/failtraces`, dataToSend)
            .then(response => {
                setIsSavingInProgress(false);
                setDpmoAvailability(response.data);
                setModifiedFailtraceItems({});
            })
            .catch(error => {
                console.warn(error.response);
                setIsSavingInProgress(false);
                setIsSaveButtonDisabled(false);
                setSnackbarVisibility(true);
                setSnackbarMessage('A mentés sikertelen!');
            });
    };

    let displayedDpmoAvailability = '';
    if (isSavingInProgress) {
        displayedDpmoAvailability = <div className={classes.placeholder40px} />;
    } else if (dpmoAvailability) {
        displayedDpmoAvailability = dpmoAvailability.toFixed(2);
    } else {
        displayedDpmoAvailability = constants.NO_DATA;
    }

    return data && selectedMonth ? (
        <div className={classes.sectionContainer}>
            <h4 className={classes.sectionTitle}>{data.scenarioDetailsItem.description} kiesései</h4>
            <div className={classes.topBar}>
                <div className={classes.selectContainer}>
                    <FormControl>
                        <InputLabel>Hónap</InputLabel>
                        <Select value={selectedMonth?.value} onChange={handleMonthSelection}>
                            {months.map(month => (
                                <MenuItem key={month.value} value={month.value}>
                                    {month.displayText}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={`${classes.topBarText} blue-text`}>
                    <b>Havi rendelkezésre állás:&nbsp;</b>
                    {displayedDpmoAvailability}
                </div>
            </div>
            <div className={classes.progressBarContainer}>{isSavingInProgress && <LinearProgress color="secondary" />}</div>
            {failtraceItems?.length > 0 && (
                <>
                    <div className={classes.buttonGroup}>
                        <Button onClick={save} variant="contained" color="secondary" disabled={isSaveButtonDisabled}>
                            Mentés
                        </Button>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow className="table-header">
                                <TableCell className="blue-text">Id</TableCell>
                                <TableCell className="blue-text">Kezdete</TableCell>
                                <TableCell className="blue-text">Vége</TableCell>
                                <TableCell className="blue-text">Időtartama (mp)</TableCell>
                                <TableCell className="blue-text">Tervezett leállás?</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {failtraceItems?.map(item => (
                                <TableRow hover key={item.id}>
                                    <TableCell className="blue-text" component="th" scope="row">
                                        {item.id || constants.NO_DATA}
                                    </TableCell>
                                    <TableCell className="blue-text">{item.startTime || constants.NO_DATA}</TableCell>
                                    <TableCell className="blue-text">{item.endTime || constants.NO_DATA}</TableCell>
                                    <TableCell className="blue-text" align="right">
                                        {item.qualityIndex || constants.NO_DATA}
                                    </TableCell>
                                    <TableCell className="blue-text" align="right">
                                        <Checkbox
                                            checked={checkedBoxes.includes(item.id)}
                                            color="secondary"
                                            onChange={event => handleCheckboxChange(event, item.id)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {failtraceItems && (
                        <TablePagination
                            page={paginationConfig.page}
                            rowsPerPage={paginationConfig.rowsPerPage}
                            rowsPerPageOptions={paginationConfig.rowsPerPageOptions}
                            onChangePage={paginationConfig.onChangePage}
                            onChangeRowsPerPage={paginationConfig.onChangeRowsPerPage}
                            count={totalElements}
                            component="div"
                        />
                    )}
                </>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarVisible}
                autoHideDuration={5000}
                onClose={() => setSnackbarVisibility(false)}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity="error"
                    action={
                        <IconButton size="small" color="inherit" onClick={() => setSnackbarVisibility(false)}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    ) : (
        <h4>Loading...</h4>
    );
}
