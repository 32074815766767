import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FormControl, FormControlLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import fileDownload from 'js-file-download';

import { CsvDelimiter } from 'enums/CsvDelimiterEnum';
import { CsvType } from 'enums/CsvTypeEnum';
import classes from 'styles/App.module.scss';
import Button from '@material-ui/core/Button';

export default function MonthlyLog({ rawData }) {
    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState({ value: '', displayText: '' });
    const [numberOfHours, setNumberOfHours] = useState('');
    const [csvDelimiter, setCsvDelimiter] = useState(CsvDelimiter.SEMICOLON);
    const [scenarios, setScenarios] = useState([]);
    const [chosenScenario, setChosenScenario] = useState('');
    const [, setIsSubmitting] = useState(false);

    useEffect(() => {
        axios
            .get('/api/scenarios/resourceItems')
            .then(response => setScenarios(response.data))
            .catch(console.warn);

        axios
            .get('/api/date/month')
            .then(resp => {
                setMonths(resp.data);
                setSelectedMonth(resp.data[0]);
            })
            .catch(console.warn);

        axios
            .get('/api/scenarios/resourceItems')
            .then(response => setScenarios(response.data))
            .catch(console.warn);
    }, []);

    const handleMonthSelection = event => {
        const freshlySelectedMonth = months.find(element => element.value === event.target.value);
        setSelectedMonth(freshlySelectedMonth);
    };

    const handleNumberInputChange = event => setNumberOfHours(event.target.value);

    const handleCsvChange = event => setCsvDelimiter(event.target.value);

    const handleScenarioChange = event => setChosenScenario(event.target.value);

    const handleDownloadButtonClick = event => {
        event.preventDefault();
        setIsSubmitting(true);
        const data = {
            sepChar: csvDelimiter,
            selectedScenarioName: chosenScenario,
        };
        data.csvType = rawData ? CsvType.DETAILS : CsvType.LOG;
        data.selectedDateValue = rawData ? numberOfHours : selectedMonth.value;

        axios
            .post(`/api/csv`, data)
            .then(response => {
                setIsSubmitting(false);
                const filename = response.headers['content-disposition'].split('filename="')[1].slice(0, -1);
                fileDownload(response.data, filename);
            })
            .catch(error => {
                console.log(error);
                setIsSubmitting(false);
            });
    };

    return (
        <Paper className="paper" elevation={2}>
            <div className="sectionTitle">{rawData ? 'Az elmúlt órák részletes adatainak letöltése' : 'Havi naplóadatok letöltése'}</div>
            <div className="verticalPlaceholder12px" />
            <div className={classes.paperBody}>
                <div className={classes.left}>
                    <b>Rendszer:</b>
                    <RadioGroup value={chosenScenario}>
                        {scenarios.map(scenario => (
                            <FormControlLabel
                                key={scenario.name}
                                value={scenario.name}
                                control={<Radio color="primary" />}
                                label={scenario.description}
                                labelPlacement="end"
                                onChange={handleScenarioChange}
                            />
                        ))}
                    </RadioGroup>
                </div>
                <div className={classes.right}>
                    <b>{rawData ? 'Hány órára visszamenőleg kéri a részletes adatokat' : 'Hónap'}</b>
                    <div className={classes.select}>
                        {rawData ? (
                            <TextField
                                value={numberOfHours}
                                onChange={handleNumberInputChange}
                                type="number"
                                helperText="Max 72 óra"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        ) : (
                            <FormControl>
                                <Select value={selectedMonth?.value} onChange={handleMonthSelection}>
                                    {months.map(month => (
                                        <MenuItem key={month.value} value={month.value}>
                                            {month.displayText}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </div>
                    <b>CSV cellaelválasztó:</b>
                    <RadioGroup value={csvDelimiter}>
                        <FormControlLabel
                            value={CsvDelimiter.COMMA}
                            control={<Radio color="primary" />}
                            label="Vessző (Angol nyelvű Windows esetén)"
                            labelPlacement="end"
                            onChange={handleCsvChange}
                        />
                        <FormControlLabel
                            value={CsvDelimiter.SEMICOLON}
                            control={<Radio color="primary" />}
                            label="Pontosvessző (Magyar nyelvű Windows esetén)"
                            labelPlacement="end"
                            onChange={handleCsvChange}
                        />
                    </RadioGroup>
                </div>
            </div>
            <div className="verticalPlaceholder12px" />
            <div className={classes.submitButtonContainer}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDownloadButtonClick}
                    className={classes.submitButton}
                    disabled={!chosenScenario || (rawData && !numberOfHours)}
                >
                    Letöltés
                </Button>
            </div>
        </Paper>
    );
}
