import Paper from '@material-ui/core/Paper';

import 'App.scss';
import axios from 'axios';
import { ServerStatus } from 'enums/ServerStatusEnum';
import { DashboardDetailType } from 'enums/DashboardDetailTypeEnum';
import React, { useEffect, useState } from 'react';
import 'styles/dashboard.scss';
import DispatcherPanel from './DispatcherPanel';
import ReportPanel from './ReportPanel';
import ScenarioPanel from './ScenarioPanel';

export default function Dashboard() {
    const [scenarioData, setScenarioData] = useState();
    const [dispatcherData, setDispatcherData] = useState();
    const [selectedDetails, setSelectedDetails] = useState();
    const [reportDurationData, setReportDurationData] = useState();

    function getDashboardData() {
        axios
            .get('/api/dashboard')
            .then(response => {
                setScenarioData(response.data.scenarioStates);
                setDispatcherData(response.data.dispatcherStates);
            })
            .catch(console.warn);
    }

    function closeDurationInfo() {
        setReportDurationData(null);
    }

    function resetDetails() {
        setSelectedDetails(null);
        closeDurationInfo();
    }

    function getScenarioDetails(runnerIp, scenarioName) {
        resetDetails();
        axios
            .get('/api/dashboard/scenario', { params: { runnerIp, scenarioName } })
            .then(response => {
                response.data.selectedType = DashboardDetailType.SCENARIO;
                setSelectedDetails(response.data);
            })
            .catch(console.warn);
    }

    function getDispatcherDetails(dispatcherIp) {
        resetDetails();
        axios
            .get('/api/dashboard/dispatcher', { params: { dispatcherIp } })
            .then(response => {
                response.data.selectedType = DashboardDetailType.DISPATCHER;
                setSelectedDetails(response.data);
            })
            .catch(console.warn);
    }

    function getReportDurationData(reportId, scenarioName) {
        axios
            .get('/api/dashboard/duration', { params: { reportId, scenarioName } })
            .then(response => {
                setReportDurationData(response.data);
            })
            .catch(console.warn);
    }

    function isSelectedTypeMatch(type) {
        return selectedDetails && selectedDetails.selectedType === type;
    }

    useEffect(() => {
        getDashboardData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            getDashboardData();
            const refreshSelectedDetails = () => {
                if (isSelectedTypeMatch(DashboardDetailType.SCENARIO)) {
                    getScenarioDetails(selectedDetails.scenarioInfo.runnerIp, selectedDetails.scenarioInfo.scenarioName);
                } else if (isSelectedTypeMatch(DashboardDetailType.DISPATCHER)) {
                    getDispatcherDetails(selectedDetails.dispatcherInfo.dispatcherIp);
                }
            };
            refreshSelectedDetails();
        }, 300000);
        return () => clearInterval(intervalId);
    });

    function isScenarioSelected(runnerIp, scenarioName) {
        return (
            isSelectedTypeMatch('scenario') && selectedDetails.scenarioInfo.runnerIp === runnerIp && selectedDetails.scenarioInfo.scenarioName === scenarioName
        );
    }

    function isDispatcherSelected(dispatcherIp) {
        return isSelectedTypeMatch('dispatcher') && selectedDetails.dispatcherInfo.dispatcherIp === dispatcherIp;
    }

    return (
        <Paper className="dashboard-background-container" elevation={2}>
            <div className="dashboard-container">
                <div className="server-data-container">
                    <div className="indicator-light-container">
                        {scenarioData?.map(scenario => (
                            <div key={scenario.runnerIp + scenario.scenarioName} onClick={() => getScenarioDetails(scenario.runnerIp, scenario.scenarioName)}>
                                <span
                                    className={`circle circle-scenario circle${ServerStatus[scenario.scenarioState]?.color} ${
                                        isScenarioSelected(scenario.runnerIp, scenario.scenarioName) ? 'selected' : 'notSelected'
                                    }`}
                                >
                                    <span>{scenario.scenarioCode}</span>
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="indicator-light-container">
                        {dispatcherData?.map(dispatcher => (
                            <div key={dispatcher.dispatcherIp} onClick={() => getDispatcherDetails(dispatcher.dispatcherIp)}>
                                <span
                                    className={`circle circle${ServerStatus[dispatcher.dispatcherState]?.color} ${
                                        isDispatcherSelected(dispatcher.dispatcherIp) ? 'selected' : 'notSelected'
                                    }`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {selectedDetails && isSelectedTypeMatch(DashboardDetailType.SCENARIO) && ScenarioPanel(selectedDetails, resetDetails, getDispatcherDetails)}
                {selectedDetails && isSelectedTypeMatch(DashboardDetailType.DISPATCHER) && DispatcherPanel(selectedDetails, resetDetails, getScenarioDetails)}
                {selectedDetails &&
                    ReportPanel(selectedDetails.reports, selectedDetails.selectedType, getReportDurationData, reportDurationData, closeDurationInfo)}
            </div>
        </Paper>
    );
}
