import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import monitoringLogo from 'assets/images/norrisheader_01.png';
import qtcLogo from 'assets/images/norrisheader_03.png';
import classes from './Header.module.scss';

export default function Header({ user }) {
    return (
        <div className={classes.container}>
            <img src={monitoringLogo} alt="logo" className={classes.logo} />
            {user && (
                <div className={classes.linkContainer}>
                    <NavLink to="/overview" className={classes.link} activeClassName="activeLink">
                        Mérések
                    </NavLink>
                    {user.isAdmin && (
                        <NavLink to="/dashboard" className={classes.link} activeClassName="activeLink">
                            Dashboard
                        </NavLink>
                    )}
                    {user.isAdmin && (
                        <NavLink to="/balance" className={classes.link} activeClassName="activeLink">
                            Egyenleg
                        </NavLink>
                    )}
                    <NavLink to="/monthly-report" className={classes.link} activeClassName="activeLink">
                        Kimutatás
                    </NavLink>
                    <NavLink to="/monthly-log" className={classes.link} activeClassName="activeLink">
                        Napló
                    </NavLink>
                    <NavLink to="/raw-data" className={classes.link} activeClassName="activeLink">
                        Nyersadatok
                    </NavLink>
                    <Link to="/logout" className={classes.link}>
                        Kijelentkezés
                    </Link>
                </div>
            )}
            <img src={qtcLogo} alt="logo" className={classes.logo} />
        </div>
    );
}
