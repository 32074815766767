import React, { useEffect, useState } from 'react';

import { CssBaseline } from '@material-ui/core';
import { createTheme, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { muiTheme } from 'styles/muiTheme';

import Routing from 'routing/Routing';
import Header from './common/Header/Header';
import Footer from './common/Footer/Footer';
import './App.scss';
import ErrorHandler from './utils/ErrorHandler';

const theme = createTheme(muiTheme);

function App() {
    const user = localStorage.getItem('user');

    const [loggedInUser, setLoggedInUser] = useState();

    useEffect(() => {
        setLoggedInUser(JSON.parse(user));
    }, [user]);

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <ErrorHandler>
                    <CssBaseline />
                    <Header user={loggedInUser} />
                    <div className="App">
                        <Routing setLoggedInUser={setLoggedInUser} loggedInUser={loggedInUser} />
                    </div>
                    <Footer userName={loggedInUser?.username} />
                </ErrorHandler>
            </ThemeProvider>
        </StylesProvider>
    );
}

export default App;
