import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FormControl, FormControlLabel, InputLabel, LinearProgress, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';

import { PeriodType } from 'enums/PeriodTypeEnum';
import classes from 'routes/ScenarioDetails/TabData.module.scss';
import { constants } from 'utils/contstants';

export default function Charts({ data }) {
    const [chosenPeriod, setChosenPeriod] = useState(PeriodType.MONTHS.value);
    const [periodName, setPeriodName] = useState('');
    const [selectOptions, setSelectOptions] = useState();
    const [selected, setSelected] = useState('');
    const [dpmoAvailability, setDpmoAvailability] = useState();
    const [charts, setCharts] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let url = '';
        if (chosenPeriod === PeriodType.MONTHS.value) {
            setPeriodName('Havi');
            url = `/api/date/month`;
        } else if (chosenPeriod === PeriodType.WEEKS.value) {
            setPeriodName('Heti');
            url = `/api/date/week`;
        } else if (chosenPeriod === PeriodType.DAYS.value) {
            setPeriodName('Napi');
            url = `/api/date/day`;
        }

        axios
            .get(url)
            .then(response => setSelectOptions(response.data))
            .catch(console.warn);
    }, [chosenPeriod]);

    useEffect(() => {
        if (chosenPeriod && selected) {
            axios
                .get(`/api/scenarios/${data.scenarioDetailsItem.id}/dpmo/${chosenPeriod}/${selected}`)
                .then(response => setDpmoAvailability(response.data.availability))
                .catch(console.warn);

            axios
                .get(`/api/scenarios/${data.scenarioDetailsItem.id}/charts/${chosenPeriod}/${selected}`)
                .then(response => {
                    setCharts(response.data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.warn(error);
                    setIsLoading(false);
                });
        }
    }, [data.scenarioDetailsItem.id, chosenPeriod, selected]);

    const handleRadioChange = event => {
        setSelected('');
        setSelectOptions([]);
        setChosenPeriod(event.target.value);
        setCharts(null);
    };

    const handleSelection = event => {
        setCharts(null);
        setSelected(event.target.value);
        setIsLoading(true);
    };

    return (
        <div>
            <h4 className={classes.sectionTitle}>{data.scenarioDetailsItem.description}</h4>
            <RadioGroup row name="position" value={chosenPeriod}>
                <FormControlLabel
                    value={PeriodType.MONTHS.value}
                    control={<Radio color="primary" />}
                    label="Havi adatok"
                    labelPlacement="start"
                    onChange={handleRadioChange}
                />
                <FormControlLabel
                    value={PeriodType.WEEKS.value}
                    control={<Radio color="primary" />}
                    label="Heti adatok"
                    labelPlacement="start"
                    onChange={handleRadioChange}
                />
                <FormControlLabel
                    value={PeriodType.DAYS.value}
                    control={<Radio color="primary" />}
                    label="Napi adatok"
                    labelPlacement="start"
                    onChange={handleRadioChange}
                />
            </RadioGroup>
            {selectOptions && (
                <div>
                    <div className={classes.topBar}>
                        <div className={classes.selectContainer}>
                            <FormControl>
                                <InputLabel>{Object.entries(PeriodType).find(period => period[1].value === chosenPeriod)[1].displayName}</InputLabel>
                                <Select value={selected} onChange={handleSelection}>
                                    {selectOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.displayText}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={`${classes.topBarText} blue-text`}>
                            <b>{periodName} rendelkezésre állás:&nbsp;</b>
                            {dpmoAvailability?.toFixed(2) || constants.NO_DATA}
                        </div>
                    </div>
                    <div className={classes.progressBarContainer}>{isLoading && <LinearProgress color="secondary" />}</div>
                    {charts?.length && charts.filter(chart => chart.bytes).length ? (
                        <div>
                            {charts.map((chart, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={index}>
                                    <img src={`data:image/jpg|png;base64,${chart.bytes}`} className={classes.graphImage} alt={`${periodName} grafikon`} />
                                </div>
                            ))}
                        </div>
                    ) : null}
                    {charts?.length && charts.filter(chart => chart.bytes).length === 0 && (
                        <b className="textblue">A kiválaszott időszakban nincs elérhető adat.</b>
                    )}
                </div>
            )}
            {charts?.length && charts.filter(chart => chart.bytes).length ? (
                <div className={classes.linkContainer}>
                    <a className="link" href="http://en.wikipedia.org/wiki/Box_plot" target="_blank" rel="noopener noreferrer">
                        * Box plot diagrammokról bővebben
                    </a>
                </div>
            ) : null}
        </div>
    );
}
